export const menuToggle = () => {
  const $handler = document.querySelector('.js-menu-handler')

  if ($handler) {
    const $menu = document.querySelector('.js-menu')
    const $menuItems = $menu.querySelectorAll('.js-menu-item')

    const handleClick = () => {
      if ($menu.classList.contains('nav_is_visible')) {
        $menu.classList.remove('nav_is_visible')
      } else {
        $menu.classList.add('nav_is_visible')
      }
    }

    $handler.addEventListener('click', handleClick)
    $menuItems.forEach($menuItem => $menuItem.addEventListener('click', handleClick))
  }
}
