import './index.css'

import { menuToggle } from '../components/menuToggle'
import { carousel } from '../components/carousel'
import { scrollToSection } from '../components/scrollToSection'
// import { loadProducts } from '../components/loadProducts'

menuToggle()
carousel()
scrollToSection()

// document.addEventListener("DOMContentLoaded", loadProducts);
