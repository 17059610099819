const isVisible = (element) => {
  return element.offsetWidth > 0 && element.offsetHeight > 0;
}

export const scrollToSection = () => {
  const $header = document.querySelector('.header')

  if ($header) {
    const headerHeight = $header.offsetHeight
    const $handlers = $header.querySelectorAll('[data-scroll-to]')

    const handleClick = (e) => {
      e.preventDefault()

      const scrollTo = e.target.dataset.scrollTo
      const scrollToElements = document.querySelectorAll(`${scrollTo}`)

      scrollToElements.forEach(scrollToElement => {
        if (isVisible(scrollToElement)) {
          const topOfElement = scrollToElement.offsetTop - headerHeight
          window.scroll({ top: topOfElement, behavior: 'smooth' })
        }
      })
    }

    if ($handlers) {
      $handlers.forEach($handler => $handler.addEventListener('click', handleClick))
    }
  }
}
