import Glide from '@glidejs/glide'

export const carousel = () => {
  const $carousels = document.querySelectorAll('.js-carousel')

  if ($carousels) {
    $carousels.forEach($carousel => {
      new Glide($carousel, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
      }).mount()
    })
  }
};
